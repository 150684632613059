<template>
  <v-container class="fill-height">
    <v-row justify="center">
      <v-col cols="auto">
        <v-card max-width="460">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-card-text class="text-center px-12 py-16">
              <div class="text-h4 font-weight-black mb-10">로그인</div>
              <v-text-field
                v-model="loginEmail"
                :rules="loginEmailRules"
                :error-messages="emailErrors"
                label="E-mail"
                required
                prepend-icon="mdi-email"
                color="success"
              />
              <v-text-field
                v-model="loginPassword"
                :append-icon="show1 ? 'eye' : 'eye-off'"
                :rules="[rules.required, rules.min]"
                :type="show1 ? 'text' : 'password'"
                name="input-10-1"
                label="Password"
                hint="At least 6 characters"
                counter
                color="success"
                prepend-icon="mdi-lock-outline"
                @click:append="show1 = !show1"
              />
              <v-btn
                class="mt-8"
                :disabled="!valid"
                color="success"
                block
                rounded
                @click="onSubmit"
              >
                로 그 인
              </v-btn>
            </v-card-text>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
    <dialog-msg />
    <dialog-changps />
  </v-container>
</template>
<script>
import EventBus from "./EventBus";
import DialogMsg from "./dialogMessage.vue";
import DialogChangps from "./dialogChangps.vue";
import { mapActions, mapGetters } from "vuex"; // mapGetters를 추가한다

const register = "register";

export default {
  name: "LoginForm",
  components: {
    DialogMsg,
    DialogChangps,
  },
  data() {
    return {
      dialog: false,
      dialogHead: "",
      dialogMessage: "",
      valid: true,
      verify: "",
      loginPassword: "",
      loginEmail: "",
      loginEmailRules: [
        (v) => !!v || "Required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      show1: false,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => (v && v.length >= 6) || "Min 6 characters",
      },
    };
  },
  computed: {
    passwordMatch() {
      return () => this.password === this.verify || "Password must match";
    },
    ...mapGetters({
      errorState: "getErrorState", // getter로 errorState를 받는다
    }),
    emailErrors() {
      const errors = [];
      if (!this.loginEmail.$dirty) return errors;
      !this.loginEmail.email && errors.push("Must be valid e-mail");
      !this.loginEmail.required && errors.push("E-mail is required");
      return errors;
    },
  },
  methods: {
    validate() {
      this.$store
        .dispatch("login", {
          fun_code: "select",
          uid: this.loginEmail,
          password: this.loginPassword,
        })
        .then((resp) => {
          console.log(resp);
          if (resp === true) {
            console.log(true);
          } else {
            console.error(false);
          }
        });
    },

    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    ...mapActions(register, ["login", "createHashedPassword"]),
    async onSubmit() {
      try {
        const use_password = await this.createHashedPassword(
          this.loginPassword
        );
        const loginResult = await this.login({
          fun_code: "select",
          use_id: this.loginEmail,
          password: use_password,
        });
        console.log("loginResult", loginResult);
        if (loginResult.data.repCode === "00") {
          if (loginResult.data.use_init_yn === "0") {
            this.goToPages();
          } else {
            let payload = {
              Head: this.dialogHead,
              Message: this.dialogMessage,
              data: loginResult.data,
            };
            EventBus.$emit("push-changps", payload);
          }
        } else {
          console.log("loginResult", loginResult.data.repCode);

          this.dialogHead = "Error";
          this.dialogMessage = loginResult.data.repMessage;
          this.clickBtn();
        }
      } catch (err) {
        console.error(err);
      }
    },
    goToPages() {
      this.$router.push({
        name: "Home",
      });
    },
    clear() {
      this.$refs.$reset();
      this.loginEmail = "";
      this.loginPassword = "";
    },
    clickBtn() {
      let payload = { Head: this.dialogHead, Message: this.dialogMessage };
      EventBus.$emit("push-msg", payload);
    },
  },
};
</script>
<style scoped>
.alert-danger p {
  color: red;
}
a {
  text-decoration: none;
}
</style>
