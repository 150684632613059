<template>
  <login-form />
</template>

<script>
import LoginForm from "../components/loginForm";

export default {
  name: "Login",
  components: {
    LoginForm,
  },
  data() {
    return {};
  },
  methods: {
    //
  },
};
</script>
<style scoped>
.alert-danger p {
  color: red;
}
</style>
