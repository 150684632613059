<template>
  <v-container>
    <v-row>
      <v-dialog v-model="dialog" persistent max-width="350">
        <v-card flat>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-card-text class="text-center px-8 py-8">
              <div class="text-h5 font-weight-black mb-10">패스워드 변경</div>
              <v-text-field
                v-model="password"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                :type="show1 ? 'text' : 'password'"
                name="input-10-1"
                label="Password"
                hint="At least 6 characters"
                counter
                @click:append="show1 = !show1"
              />

              <v-text-field
                v-model="verify"
                block
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, passwordMatch]"
                :type="show1 ? 'text' : 'password'"
                name="input-10-1"
                label="Confirm Password"
                counter
                @click:append="show1 = !show1"
              />
              <v-row justify="center">
                <v-col class="flat" cols="6">
                  <v-btn
                    class="mt-8"
                    :disabled="!valid"
                    color="success"
                    block
                    rounded
                    @click="onSubmit"
                  >
                    확인
                  </v-btn>
                </v-col>
                <v-col class="flat" cols="6">
                  <v-btn
                    class="mt-8"
                    color="success"
                    block
                    rounded
                    @click="dialog = false"
                  >
                    취소
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<script>
import EventBus from "./EventBus";
import { mapActions } from "vuex"; // mapGetters를 추가한다

const register = "register";
export default {
  data() {
    return {
      dialog: false,
      dialogHead: "",
      dialogMessage: "",
      count: 0,
      show1: false,
      valid: true,
      verify: "",
      email: "",
      password: "",
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => (v && v.length >= 6) || "Min 6 characters",
      },
    };
  },
  computed: {
    passwordMatch() {
      return () => this.password === this.verify || "Password must match";
    },
  },
  created() {
    EventBus.$on("push-changps", (payload) => {
      this.dialogHead = payload.Head;
      this.dialogMessage = payload.Message;
      this.email = payload.data.use_id;
      this.count++;
      this.dialog = true;
      console.log(this.count + " " + payload.data.use_id);
    });
  },
  methods: {
    ...mapActions(register, ["Register", "createHashedPassword"]),
    async onSubmit() {
      try {
        console.log(this.email);
        const use_password = await this.createHashedPassword(this.password);
        const loginResult = await this.Register({
          fun_code: "changps",
          use_id: this.email,
          use_password: use_password,
          use_yn: "1",
          use_init_yn: "0",
        });
        console.log("loginResult", loginResult);
        if (loginResult.data.repCode === "00") {
          this.dialog = false;
        } else {
          console.log("loginResult", loginResult.data.repCode);
          this.dialog = true;
          this.dialogMessage = loginResult.data.repMessage;
        }
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>
